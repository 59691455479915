<!--产品信息详情-->
<template>
  <div class="main-div">
    <!--面包屑-->
    <div class="breadTopDiv">
      <el-row class="breadTop">
        <el-col>
          <top-path-list :path-obj="pathObj"></top-path-list>
        </el-col>
      </el-row>
    </div>
    <div class="content-div">
      <el-row>
        <el-col :span="24">
          <div class="w-e-text-container">
            <div v-html="projectData" data-slate-editor></div>
          </div>
        </el-col>
      </el-row>
    </div>
    <!--增加取消下方白条-->
    <div style="height: 20px"></div>
  </div>
</template>

<script>
import topPathList from '@/components/topPathList'
import axios from 'axios'
export default {
  name: 'productInfoDetail',
  components: { topPathList },
  data() {
    return {
      pathObj: [
        {
          name: '首页',
          path: '/'
        },
        {
          name: '地标品牌',
          path: ''
        },
        {
          name: '地标产品',
          path: '/richPeasant/index'
        },
        {
          name: '产品信息',
          path: '/richPeasant/productInfo',
          params:[
            {
              key: 'id',
              value: this.$route.query.id
            }
          ]
        },
        {
          name: '产品详情',
          path: '/richPeasant/productInfoDetail'
        },
      ],
      title: '',
      place: '',
      remak: '',
      img: '',
      projectId: '',
      projectData: '', //富文本内容

    }
  },
  mounted () {
    this.getProjectInfo()
  },
  methods: {
    //产品详情查询
    getProjectInfo() {
      this.projectId = this.$route.query.id
      let params = {
        id: this.projectId
      }
      axios({
        method: "get",
        url: process.env.VUE_APP_HUSSAR_DEFAULT_API + "/api/portal/productManage/productDetail/formData",
        params: params
      }).then(res => {
        if (res.data.code === 10000 || res.data.code === 200) {
          this.projectData = res.data.data.discription //图文描述

        } else {
          this.$message.warning("请求失败")
        }
      }).catch(err => {
        this.$message.error('请求失败')
      })
    },

  }
}
</script>

<style scoped lang="less">
@import '~@/assets/css/common.less';
.main-div {
  background-color: #f8f9fa !important;
}
.breadTopDiv {
  text-align: center;
  .breadTop {
    width: 1200px;
    margin: auto;
  }
}
.content-div {
  min-height: 525px;
  margin: auto;
  padding: 32px 20px 12px 20px;
  width: 1160px;
  background: #FFFFFF;

}

::v-deep .el-row {
  margin: 10px 0;
}
.infoTitle {
  font-weight: bold;
  margin: 0 0 20px 0;
  font-size: 20px;
  color: #303133;
}
</style>
